import { useParticipant } from "@livekit/react-core";
import { Room } from "livekit-client";

import { ReactComponent as CloseIcon } from "./icons/close.svg";
import { ReactComponent as LeaveIcon } from "./icons/leave.svg";
import { ReactComponent as MicOffIcon } from "./icons/micOff.svg";
import { ReactComponent as MicOnIcon } from "./icons/micOn.svg";
import { ReactComponent as VideoOffIcon } from "./icons/videoOff.svg";
import { ReactComponent as VideoOnIcon } from "./icons/videoOn.svg";
import { flex, flexCenter, fullSize } from "./styles";

export const LivekitToolbar = ({
  room,
  onLeave,
  onTerminate,
}: {
  room: Room;
  onLeave?: () => void;
  onTerminate?: () => void;
}) => {
  const { microphonePublication, cameraPublication } = useParticipant(
    room.localParticipant,
  );

  const microphoneEnabled = !microphonePublication?.isMuted;
  const cameraEnabled = !cameraPublication?.isMuted;

  const toggleMicrophone = () => {
    room.localParticipant.setMicrophoneEnabled(!microphoneEnabled).then();
  };
  const toggleCamera = () => {
    room.localParticipant.setCameraEnabled(!cameraEnabled).then();
  };
  return (
    <div style={{ ...flex }}>
      <LivekitButton
        Icon={microphoneEnabled ? MicOnIcon : MicOffIcon}
        onClick={toggleMicrophone}
      />
      <LivekitButton
        Icon={cameraEnabled ? VideoOnIcon : VideoOffIcon}
        onClick={toggleCamera}
      />
      {onLeave && <LivekitButton Icon={LeaveIcon} onClick={onLeave} />}
      {onTerminate && (
        <LivekitButton Icon={CloseIcon} danger onClick={onTerminate} />
      )}
    </div>
  );
};

const LivekitButton = ({
  Icon,
  danger,
  onClick,
}: {
  Icon: React.ComponentType<React.ComponentProps<"svg">>;
  danger?: boolean;
  onClick?: () => void;
}) => (
  <button
    style={{
      ...flexCenter,
      width: 40,
      height: 40,
      padding: 8,
      marginLeft: 7,
      marginRight: 7,
      borderRadius: 40,
      color: danger ? "#ffffff" : "#6d758d",
      backgroundColor: danger ? "#ff323d" : "#ffffff",
    }}
    onClick={onClick}
  >
    <Icon style={{ ...fullSize }} />
  </button>
);
