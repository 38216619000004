import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import { AppContext } from "../App";
import Layout from "../components/Layout";
import { SlotPicker } from "../components/SlotPicker";

export const SchedulePage = () => {
  const { apiData } = useOutletContext<AppContext>();
  const navigate = useNavigate();

  return (
    <Layout title="Pick an appointment">
      <SlotPicker
        slots={apiData.invitationState.availableSlots ?? []}
        onPick={(time: Date) => navigate(`${time.getTime()}`)}
      />
    </Layout>
  );
};
