import React, { useEffect } from "react";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";

import { ApiHookOutput, ApiData, useApi } from "./api";
import Layout from "./components/Layout";
import { Page } from "./components/Page";
import { Spinner } from "./components/Spinner";

export type AppContext = Omit<ApiHookOutput, "apiState"> & {
  apiData: ApiData;
};

export const App = () => {
  const { token } = useParams();
  const { apiState, ...apiRest } = useApi(token as string);
  const navigate = useNavigate();
  const location = useLocation();

  const invitationState =
    apiState.status === "SUCCESS"
      ? apiState.data.invitationState.__typename
      : undefined;

  // Force-redirect to the right path depending on the current state.
  useEffect(
    () => {
      const navigateIfNeeded = (to: string) => {
        if (location.pathname.startsWith(`/${token}/${to}`)) return;
        navigate(to, { replace: true });
      };

      switch (invitationState) {
        case "InvitationCreated":
          return navigateIfNeeded("schedule");
        case "WebCallScheduled":
          return navigateIfNeeded("summary");
        case "WebCallCancelled":
          return navigateIfNeeded("cancelled");
        case "WebCallFinalized":
          return navigateIfNeeded("finalized");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invitationState],
  );

  return (() => {
    switch (apiState.status) {
      case "SUCCESS":
        return (
          <Page {...apiState.data.configuration}>
            <Outlet context={{ ...apiRest, apiData: apiState.data }} />
          </Page>
        );
      case "LOADING":
        return (
          <Page>
            <Spinner />
          </Page>
        );
      case "ERROR":
        return (
          <Page>
            <Layout>
              <p className="text-center">
                An error occurred while loading the page. Please try again
                later.
              </p>
            </Layout>
          </Page>
        );
    }
  })();
};
