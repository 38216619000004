import { CSSProperties } from "react";

/**
 * Inline styles which mimic Tailwind helpers.
 */
const s = (...args: CSSProperties[]): CSSProperties =>
  args.reduce((other, current) => ({ ...other, ...current }));

export const absolute = s({ position: "absolute" });
export const relative = s({ position: "relative" });
export const fullWidth = s({ width: "100%" });
export const fullHeight = s({ height: "100%" });
export const fullSize = s(fullWidth, fullHeight);
export const flex = s({ display: "flex" });
export const itemsCenter = s({ alignItems: "center" });
export const justifyCenter = s({ justifyContent: "center" });
export const flexCenter = s(flex, itemsCenter, justifyCenter);
export const shadow = s({
  boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
});

/**
 * Standard dimensions.
 */
export const STATUS_INSET_MINIMIZED = 10;
export const STATUS_INSET_LARGE = 15;
export const CONTROLS_INSET_MINIMIZED = 10;
export const CONTROLS_INSET_LARGE = 25;
