import {
  CalendarIcon,
  ClockIcon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import React from "react";

import { toFullDate, toHoursMinutes } from "../utils";

export const AppointmentDetails = ({
  time,
  firstName,
  lastName,
  phone,
  className,
}: {
  time: Date;
  firstName: string;
  lastName: string;
  phone: string | null;
  className?: string;
}) => (
  <div
    className={classNames(
      "flex flex-col sm:flex-row self-center justify-center sm:self-start sm:justify-start sm:divide-x divide-divider space-y-9 sm:space-y-0 flex-grow text-text",
      className,
    )}
  >
    <div className="flex flex-col space-y-1 sm:pr-8">
      <h3 className="text-sm font-semibold mb-2">Your appointment</h3>
      <div className="flex items-center space-x-1.5">
        <CalendarIcon height={20} />
        <div>{toFullDate(time)}</div>
      </div>
      <div className="flex items-center space-x-1.5">
        <ClockIcon height={20} />
        <div>{toHoursMinutes(time)}</div>
      </div>
    </div>
    <div className="flex flex-col space-y-1 sm:pl-8">
      <h3 className="text-sm font-semibold mb-2">Your details</h3>
      <div className="flex items-center space-x-1.5">
        <UserIcon height={20} />
        <div>
          {firstName} {lastName}
        </div>
      </div>
      {phone && (
        <div className="flex items-center space-x-1.5">
          <PhoneIcon height={20} />
          <div>+{phone}</div>
        </div>
      )}
    </div>
  </div>
);
