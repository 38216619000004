/** List helpers. */
export const range = (start: number = 0, stop?: number, step: number = 1) => {
  let cur = stop === undefined ? 0 : start;
  let max = stop === undefined ? start : stop;
  let array = [];
  for (let i = cur; step < 0 ? i > max : i < max; i += step) array.push(i);
  return array;
};

/** Date and time helpers. */
export const plusDays = (date: Date, days: number) => {
  let clonedDate = new Date(date.valueOf());
  clonedDate.setDate(clonedDate.getDate() + days);
  return clonedDate;
};

export const startOfDay = (date: Date) => {
  let clonedDate = new Date(date.valueOf());
  clonedDate.setUTCHours(0, 0, 0, 0);
  return clonedDate;
};

// Returns the browser's current offset from UTC in the `[+-]hh:mm` form.
export const getTimezoneOffset = () => {
  const offset = new Date().getTimezoneOffset();
  const sign = offset > 0 ? "-" : "+"; // Sign is the other way around.
  const hours = Math.trunc(Math.abs(offset) / 60)
    .toString()
    .padStart(2, "0");
  const minutes = Math.trunc(Math.abs(offset) % 60)
    .toString()
    .padStart(2, "0");
  return `${sign}${hours}:${minutes}`;
};

// TODO(@liautaud): Replace by [] to let the browser guess.
const LOCALES = "en-US";

export const toShortWeekday = (date: Date) =>
  date.toLocaleDateString(LOCALES, { weekday: "short" });

export const toShortMonthDay = (date: Date) =>
  date.toLocaleDateString(LOCALES, { month: "short", day: "numeric" });

export const toFullDate = (date: Date) =>
  date.toLocaleDateString(LOCALES, {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

export const toHoursMinutes = (date: Date) =>
  date.toLocaleTimeString(LOCALES, { hour: "2-digit", minute: "2-digit" });

/** String helpers. */
export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

/** Color helpers. */
export type HexColor = string;

export const hexColorToRgb = (color: HexColor) => {
  const int = parseInt(color.substring(1), 16);
  return [(int >> 16) & 255, (int >> 8) & 255, int & 255];
};

/** URL helpers. */
export const url = (
  path: string,
  params: Record<string, string> = {},
): string => {
  const url = new URL(path);
  Object.entries(params).forEach(([name, value]) =>
    url.searchParams.append(name, value),
  );
  return url.toString();
};
