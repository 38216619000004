import classNames from "classnames";
import React, { MouseEventHandler } from "react";

export const PrimaryButton = ({
  type,
  label,
  className,
  onClick,
}: {
  type?: "submit" | "reset" | "button";
  label: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) => (
  <button
    type={type}
    className={classNames(
      "text-center items-center px-4 py-3 border border-transparent font-medium rounded-3xl text-white bg-primary/90 hover:bg-primary/75 focus:outline-none focus:ring-2 focus:ring-primary/40",
      className,
    )}
    onClick={onClick}
  >
    {label}
  </button>
);

export const SecondaryButton = ({
  type,
  label,
  className,
  onClick,
}: {
  type?: "submit" | "reset" | "button";
  label: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) => (
  <button
    type={type}
    className={classNames(
      "text-center items-center px-4 py-3 border border-text-lighter font-medium rounded-3xl text-text-light hover:bg-white/5 focus:outline-none focus:ring-2 focus:ring-gray-500",
      className,
    )}
    onClick={onClick}
  >
    {label}
  </button>
);

export const SecondaryLink = ({
  className,
  children,
  ...rest
}: React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>) => (
  <a
    className={classNames(
      "text-center items-center px-4 py-3 border border-text-lighter font-medium rounded-3xl text-text-light hover:bg-white/5 focus:outline-none focus:ring-2 focus:ring-gray-500",
      className,
    )}
    {...rest}
  >
    {children}
  </a>
);
