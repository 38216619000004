import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import { AppContext } from "../App";
import { LivekitRoom } from "../components/Livekit/LivekitRoom";
import { Spinner } from "../components/Spinner";

export const CallPage = () => {
  const { apiData } = useOutletContext<AppContext>();
  const [firstLivekitToken, setFirstLivekitToken] = useState<string>();
  const { livekitUrl, livekitToken } = apiData.invitationState;
  const navigate = useNavigate();

  // The token is refreshed regularly, but we only want to keep the first valid
  // one to avoid destroying and re-creating the room unnecessarily every time.
  useEffect(() => {
    if (!firstLivekitToken) setFirstLivekitToken(livekitToken);
  }, [firstLivekitToken, livekitToken]);

  return (
    <>
      {livekitUrl && firstLivekitToken && (
        <div className="fixed inset-0 w-full h-full z-10">
          <LivekitRoom
            url={livekitUrl}
            token={firstLivekitToken}
            onTerminate={() => navigate("../summary")}
            emptyRoomLabel="Waiting for the doctor to join"
          />
        </div>
      )}
      <Spinner />
    </>
  );
};
