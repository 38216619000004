import { Track } from "livekit-client";
import { useEffect, useRef } from "react";

/**
 * Reimplementation of LiveKit's <VideoRenderer> which fixes a Safari bug with
 * local video (see https://github.com/twilio/twilio-video.js/issues/1724).
 */
export const LivekitVideoRenderer = ({
  track,
  isLocal,
  style,
}: {
  track: Track;
  isLocal: boolean;
  style?: React.CSSProperties;
}) => {
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const el = ref.current;
    if (!el) return;
    el.muted = true;
    track.attach(el);
    return () => {
      track.detach(el);
    };
  }, [track, ref]);

  const isFrontFacing =
    isLocal &&
    track.mediaStreamTrack.getSettings().facingMode !== "environment";

  return (
    <video
      ref={ref}
      style={{
        ...style,
        transform: isFrontFacing ? "scaleX(-1)" : "",
      }}
    />
  );
};
