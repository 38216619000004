import { useEffect, useState } from "react";

import breakpoints, { ScreenName } from "./screens.config";

/** Dynamically returns if the current screen size matches a Tailwind screen name. */
export function useMatchesScreen(breakpoint: ScreenName): boolean {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const mediaQuery = `(min-width: ${breakpoints[breakpoint]})`;
    const queryList = window.matchMedia(mediaQuery);
    const updateMatches = () => setMatches(queryList.matches);
    queryList.addEventListener("change", updateMatches);
    updateMatches();
    return () => queryList.removeEventListener("change", updateMatches);
  }, [breakpoint]);

  return matches;
}

/** Dynamically returns the current date. */
export function useCurrentDate(): Date {
  const [date, setDate] = useState<Date>(new Date());

  useEffect(() => {
    const id = setInterval(() => setDate(new Date()), 1000);
    return () => clearInterval(id);
  });

  return date;
}
