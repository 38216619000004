import {
  CheckCircleIcon,
  ClockIcon,
  VideoCameraIcon,
} from "@heroicons/react/outline";
import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import { AppContext } from "../App";
import { AppointmentDetails } from "../components/AppointmentDetails";
import { PrimaryButton, SecondaryButton } from "../components/Button";
import Layout from "../components/Layout";
import { useCurrentDate } from "../hooks";

const WAITING_ROOM_THRESHOLD_MINUTES = 15;

export const SummaryPage = () => {
  const currentTime = useCurrentDate();
  const navigate = useNavigate();
  const { apiData } = useOutletContext<AppContext>();

  if (!apiData.invitationState.scheduledAt) return <></>;
  const appointmentTime = new Date(apiData.invitationState.scheduledAt);

  const remainingMinutes = Math.ceil(
    Math.max(0, appointmentTime.getTime() - currentTime.getTime()) /
      (1000 * 60),
  );

  return (
    <Layout>
      <div className="flex flex-col max-w-lg w-full self-center flex-grow">
        <div className="flex-center space-x-3 sm:space-x-4 text-primary text-lg sm:text-xl font-medium">
          {remainingMinutes > WAITING_ROOM_THRESHOLD_MINUTES ? (
            <>
              <CheckCircleIcon className="h-10 sm:h-12" />
              <div>Your appointment was booked</div>
            </>
          ) : remainingMinutes > 0 ? (
            <>
              <ClockIcon className="h-10 sm:h-12" />
              <div>Your appointment is in {remainingMinutes} minutes</div>
            </>
          ) : (
            <>
              <VideoCameraIcon className="h-10 sm:h-12" />
              <div>Your appointment is starting</div>
            </>
          )}
        </div>

        <AppointmentDetails
          time={appointmentTime}
          firstName={apiData.patientSummary.firstName}
          lastName={apiData.patientSummary.lastName}
          phone={apiData.patientSummary.phone}
          className="sm:my-8"
        />

        {remainingMinutes > WAITING_ROOM_THRESHOLD_MINUTES ? (
          <>
            <p className="text-text-light mb-9">
              We will send you a reminder by email and SMS 15 minutes before
              your appointment. If you need to cancel your appointment, please
              use the button below.
            </p>

            <SecondaryButton
              label="Cancel the appointment"
              onClick={() => navigate("cancel")}
            />
          </>
        ) : (
          <>
            <p className="text-text-light mb-9">
              Please make sure you're in a quiet environment with a high-speed
              internet connection, then join the{" "}
              {remainingMinutes > 0 ? "waiting room" : "call"} to check your
              microphone and camera by using the button below.
            </p>

            <PrimaryButton
              label={
                remainingMinutes > 0 ? "Join the waiting room" : "Join the call"
              }
              onClick={() => navigate("call")}
            />
          </>
        )}
      </div>
    </Layout>
  );
};
