import React from "react";
import { useOutletContext, useParams } from "react-router-dom";

import { AppContext } from "../App";
import { AppointmentDetails } from "../components/AppointmentDetails";
import { PrimaryButton } from "../components/Button";
import Layout from "../components/Layout";

export const ScheduleConfirmPage = () => {
  const { apiData, mutations } = useOutletContext<AppContext>();
  const { timestamp } = useParams();
  const time = new Date(parseInt(timestamp ?? ""));

  return (
    <Layout title="Confirm your appointment" showBack>
      <div className="flex flex-col max-w-lg w-full self-center flex-grow">
        <p className="text-text-light">
          Please review the details of your appointment carefully, then confirm
          it using the button below. You you will still be able to cancel your
          appointment later on if needed.
        </p>

        <AppointmentDetails
          time={time}
          firstName={apiData.patientSummary.firstName}
          lastName={apiData.patientSummary.lastName}
          phone={apiData.patientSummary.phone}
          className="sm:my-8"
        />

        <PrimaryButton
          label="Confirm the appointment"
          className="mt-4 mb-3"
          onClick={() => mutations.pickSlot(time)}
        />
      </div>
    </Layout>
  );
};
