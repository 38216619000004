import { AudioRenderer, useRoom } from "@livekit/react-core";
import { LocalParticipant, Participant, Room } from "livekit-client";
import { useEffect, useState } from "react";

import { LivekitParticipant } from "./LivekitParticipant";
import { LivekitStatusMessage } from "./LivekitStatusMessage";
import { LivekitToolbar } from "./LivekitToolbar";
import {
  absolute,
  CONTROLS_INSET_LARGE,
  CONTROLS_INSET_MINIMIZED,
  flexCenter,
  fullSize,
  fullWidth,
  relative,
  shadow,
  STATUS_INSET_LARGE,
  STATUS_INSET_MINIMIZED,
} from "./styles";

export const LivekitRoom = ({
  url,
  token,
  onLeave,
  onTerminate,
  minimized,
  emptyRoomLabel,
}: {
  url: string;
  token: string;
  onLeave?: () => void;
  onTerminate?: () => void;
  minimized?: boolean;
  emptyRoomLabel?: string;
}) => {
  const [isToolbarVisible, setIsToolbarVisible] = useState(false);
  const { connect, audioTracks, participants, room } = useRoom({
    adaptiveStream: true,
    dynacast: true,
  });

  useEffect(() => {
    let hasAborted = false;
    let currentRoom: Room | undefined = undefined;

    connect(url, token).then((connectedRoom) => {
      if (!connectedRoom) return;
      if (hasAborted) return;
      currentRoom = connectedRoom;
      currentRoom.localParticipant.enableCameraAndMicrophone().then();
    });

    return () => {
      hasAborted = true;
      currentRoom?.disconnect();
    };
  }, [connect, url, token]);

  // We only support 1-to-1 rooms at the moment.
  const isLocal = (p: Participant) => p instanceof LocalParticipant;
  const localParticipant = participants.find((p) => isLocal(p));
  const otherParticipant = participants.find((p) => !isLocal(p));

  return (
    <div
      style={{ ...fullSize, ...relative }}
      onMouseEnter={() => setIsToolbarVisible(true)}
      onMouseLeave={() => setIsToolbarVisible(false)}
      onTouchEnd={() => setIsToolbarVisible((previous) => !previous)}
    >
      {audioTracks.map((track) => (
        <AudioRenderer key={track.sid} track={track} isLocal={false} />
      ))}

      {localParticipant && !otherParticipant && (
        <>
          <div style={{ ...fullSize, backgroundColor: "black" }}>
            <LivekitParticipant participant={localParticipant} />
          </div>

          {emptyRoomLabel && (
            <div
              style={{
                ...absolute,
                ...flexCenter,
                ...fullWidth,
                zIndex: 10,
                top: minimized ? STATUS_INSET_MINIMIZED : STATUS_INSET_LARGE,
              }}
            >
              <LivekitStatusMessage label={emptyRoomLabel} />
            </div>
          )}
        </>
      )}

      {localParticipant && otherParticipant && (
        <>
          <div style={{ ...fullSize, backgroundColor: "black" }}>
            <LivekitParticipant participant={otherParticipant} />
          </div>

          <LivekitParticipant
            style={{
              ...absolute,
              ...shadow,
              zIndex: 10,
              bottom: minimized
                ? CONTROLS_INSET_MINIMIZED
                : CONTROLS_INSET_LARGE,
              right: minimized
                ? CONTROLS_INSET_MINIMIZED
                : CONTROLS_INSET_LARGE,
              borderRadius: 8,
              overflow: "hidden",
              width: "auto",
              height: "auto",
              maxWidth: "min(300px, 40%)",
              maxHeight: "min(300px, 30%)",
            }}
            participant={localParticipant}
          />
        </>
      )}

      {room && (
        <div
          style={{
            ...absolute,
            ...fullWidth,
            ...flexCenter,
            zIndex: 50,
            bottom: minimized ? CONTROLS_INSET_MINIMIZED : CONTROLS_INSET_LARGE,
            opacity: isToolbarVisible ? 100 : 0,
            visibility: isToolbarVisible ? "visible" : "hidden",
            transition: "opacity .2s, visibility .2s",
          }}
          onTouchEnd={(e) => e.stopPropagation()}
        >
          <LivekitToolbar
            room={room}
            onLeave={onLeave}
            onTerminate={onTerminate}
          />
        </div>
      )}
    </div>
  );
};
