import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { App } from "./App";
import { CallPage } from "./pages/CallPage";
import { CancelConfirmPage } from "./pages/CancelConfirmPage";
import { CancelledPage } from "./pages/CancelledPage";
import { FinalizedPage } from "./pages/FinalizedPage";
import { IndexPage } from "./pages/IndexPage";
import { ScheduleConfirmPage } from "./pages/ScheduleConfirmPage";
import { SchedulePage } from "./pages/SchedulePage";
import { SummaryPage } from "./pages/SummaryPage";
import "./styles/index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path=":token" element={<App />}>
          <Route path="schedule" element={<SchedulePage />} />
          <Route path="schedule/:timestamp" element={<ScheduleConfirmPage />} />
          <Route path="summary" element={<SummaryPage />} />
          <Route path="summary/cancel" element={<CancelConfirmPage />} />
          <Route path="summary/call" element={<CallPage />} />
          <Route path="cancelled" element={<CancelledPage />} />
          <Route path="finalized" element={<FinalizedPage />} />
        </Route>
        <Route path="/" element={<IndexPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);
