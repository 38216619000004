import { useEffect, useState } from "react";

import { appRedirectUrl, backendUrl, environment } from "./environment";
import { useCurrentDate } from "./hooks";
import {
  getTimezoneOffset,
  HexColor,
  plusDays,
  range,
  startOfDay,
} from "./utils";

async function needsIapRedirect() {
  if (environment() !== "staging") return false;

  try {
    const response = await fetch(`${backendUrl()}/health`, {
      method: "GET",
      credentials: "include",
    });
    return response.status !== 200;
  } catch (e) {
    return true;
  }
}

async function query(token: string, query: string, variables: object) {
  const response = await fetch(
    `${backendUrl()}/v1/webcall/graphql/authenticated`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    },
  );

  if (!response.ok) throw new Error(response.statusText);
  const state = await response.json();
  return state.data;
}

const MAIN_GRAPHQL_QUERY = `
query($slotsStartDate: DateTime!, $slotsEndDate: DateTime!) {
  configuration {
    logoUrl
    faviconUrl
    theme {
      primaryColor
      textColor
      textLightColor
      textLighterColor
      dividerColor
      backgroundColor
      cardColor
    }
  }
  patientSummary {
    firstName
    lastName
    phone
    email
  }
  invitationState {
    __typename

    ...on InvitationCreated {
      availableSlots(from: $slotsStartDate, to: $slotsEndDate)
    }

    ...on WebCallScheduled {
      scheduledAt
      livekitUrl
      livekitToken
    }

    ...on WebCallCancelled {
      scheduledAt
    }

    ...on WebCallFinalized {
      finalizedAt
      attachments {
        fileName
        urlV2 {
          url
        }
      }
    }
  }
}
`;

const PICK_SLOT_QUERY = `
mutation($time: DateTime!, $timeZone: TimeZone!) {
  pickSlot(time: $time, timeZone: $timeZone)
}
`;

const CANCEL_SLOT_QUERY = `
mutation {
  cancelSlot
}
`;

export type Theme = {
  primaryColor: HexColor;
  textColor: HexColor;
  textLightColor: HexColor;
  textLighterColor: HexColor;
  dividerColor: HexColor;
  backgroundColor: HexColor;
  cardColor: HexColor;
};

type RawApiData = {
  configuration: {
    logoUrl: string | null;
    faviconUrl: string | null;
    theme: Theme | null;
  };
  patientSummary: {
    firstName: string;
    lastName: string;
    phone: string | null;
    email: string | null;
  };
  invitationState: {
    __typename:
      | "InvitationCreated"
      | "WebCallScheduled"
      | "WebCallCancelled"
      | "WebCallFinalized";
    availableSlots?: string[];
    scheduledAt?: string;
    livekitUrl?: string;
    livekitToken?: string;
    finalizedAt?: string;
    attachments?: { fileName: string; urlV2: { url: string } }[];
  };
};

export type ApiState =
  | { status: "LOADING" }
  | { status: "ERROR" }
  | { status: "SUCCESS"; data: ApiData };

export type ApiData = Omit<RawApiData, "invitationState"> & {
  invitationState: Omit<
    RawApiData["invitationState"],
    "availableSlots" | "scheduledAt" | "finalizedAt"
  > & {
    availableSlots?: {
      day: Date;
      slots: Date[];
    }[];
    scheduledAt?: Date;
    finalizedAt?: Date;
  };
};

export type ApiMutations = {
  pickSlot: (time: Date) => void;
  cancelSlot: () => void;
};

export type ApiHookOutput = {
  apiState: ApiState;
  setSlotsStartDate: (day: Date) => void;
  mutations: ApiMutations;
};

const parseRawApiData = (
  { invitationState, ...rest }: RawApiData,
  slotsStartDate: Date,
): ApiData => {
  const currentDate = new Date();

  // Parse the raw slots to put them into separate buckets.
  const startDay = startOfDay(slotsStartDate);
  const allSlotsByDay = new Map<number, { day: Date; slots: Date[] }>(
    range(7).map((i) => {
      const day = startOfDay(plusDays(startDay, i));
      return [day.getTime(), { day: day, slots: [] }];
    }),
  );
  invitationState.availableSlots?.forEach((slot?: string) => {
    if (!slot) return;
    const date = new Date(slot);
    if (date < currentDate) return;
    allSlotsByDay.get(startOfDay(date).getTime())?.slots?.push(date);
  });

  return {
    ...rest,
    invitationState: {
      ...invitationState,
      availableSlots: Array.from(allSlotsByDay.values()),
      scheduledAt: invitationState.scheduledAt
        ? new Date(invitationState.scheduledAt)
        : undefined,
      finalizedAt: invitationState.finalizedAt
        ? new Date(invitationState.finalizedAt)
        : undefined,
    },
  };
};

export function useApi(token: string): ApiHookOutput {
  const [apiState, setApiState] = useState<ApiState>({ status: "LOADING" });

  const currentDate = useCurrentDate();
  const [slotsStartDate, setSlotsStartDate] = useState(currentDate);

  const refreshApiState = () => {
    query(token, MAIN_GRAPHQL_QUERY, {
      slotsStartDate,
      slotsEndDate: plusDays(slotsStartDate, 7),
    })
      .catch(() => setApiState({ status: "ERROR" }))
      .then((rawState: RawApiData) => {
        setApiState({
          status: "SUCCESS",
          data: parseRawApiData(rawState, slotsStartDate),
        });
      });
  };

  // Force users to log-in via IAP if needed.
  useEffect(() => {
    needsIapRedirect().then((doRedirect) => {
      if (doRedirect)
        window.location.href = appRedirectUrl(window.location.href);
    });
  }, []);

  useEffect(
    () => {
      refreshApiState();

      // TODO(@liautaud): Replace this with a proper GraphQL subscription.
      const interval = setInterval(refreshApiState, 5000);
      return () => clearInterval(interval);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, slotsStartDate],
  );

  const mutations: ApiMutations = {
    pickSlot: (time) => {
      query(token, PICK_SLOT_QUERY, {
        time,
        timeZone: getTimezoneOffset(),
      }).then(refreshApiState);
    },
    cancelSlot: () => {
      query(token, CANCEL_SLOT_QUERY, {}).then(refreshApiState);
    },
  };

  return { apiState, setSlotsStartDate, mutations };
}
