import React from "react";
import { useOutletContext } from "react-router-dom";

import { AppContext } from "../App";
import { PrimaryButton } from "../components/Button";
import Layout from "../components/Layout";
import { toFullDate } from "../utils";

export const CancelConfirmPage = () => {
  const { apiData, mutations } = useOutletContext<AppContext>();

  if (!apiData.invitationState.scheduledAt) return <></>;
  const appointmentTime = new Date(apiData.invitationState.scheduledAt);

  return (
    <Layout title="Cancel your appointment" showBack>
      <div className="flex flex-col max-w-lg w-full self-center flex-grow">
        <p className="text-text-light text-center mt-4 mb-10">
          Are you sure you want to cancel your {toFullDate(appointmentTime)}{" "}
          appointment?
        </p>

        <PrimaryButton
          label="Cancel the appointment"
          onClick={mutations.cancelSlot}
        />
      </div>
    </Layout>
  );
};
