import { useParticipant } from "@livekit/react-core";
import { Participant } from "livekit-client";
import { CSSProperties } from "react";

import { LivekitVideoRenderer } from "./LivekitVideoRenderer";
import { ReactComponent as VideoOffIcon } from "./icons/videoOff.svg";
import { flexCenter, fullSize } from "./styles";

export const LivekitParticipant = ({
  participant,
  style,
}: {
  participant: Participant;
  style?: CSSProperties;
}) => {
  const { cameraPublication, isLocal } = useParticipant(participant);

  if (
    !cameraPublication ||
    !cameraPublication.track ||
    cameraPublication.isMuted
  ) {
    return (
      <div
        style={{
          ...fullSize,
          ...style,
          ...flexCenter,
          color: "#262626ff",
          backgroundColor: "#525252ff",
        }}
      >
        <div
          style={{
            width: 100,
            height: 100,
            ...flexCenter,
          }}
        >
          <VideoOffIcon style={{ width: 60 }} />
        </div>
      </div>
    );
  }

  return (
    <LivekitVideoRenderer
      style={{
        ...fullSize,
        ...style,
        objectFit: "contain",
      }}
      track={cameraPublication.track}
      isLocal={isLocal}
    />
  );
};
