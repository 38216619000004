import classNames from "classnames";
import React, { CSSProperties, useEffect } from "react";

import { Theme } from "../api";
import defaultFavicon from "../images/favicon.png";
import { HexColor, hexColorToRgb } from "../utils";

export const Page = ({
  logoUrl,
  faviconUrl,
  theme,
  children,
}: {
  logoUrl?: string | null;
  faviconUrl?: string | null;
  theme?: Theme | null;
  children: React.ReactNode;
}) => {
  // Dynamically update the favicon.
  useEffect(() => {
    const link = document.querySelector("link[rel=icon]") as HTMLLinkElement;
    link.href = faviconUrl || defaultFavicon;
  }, [faviconUrl]);

  return (
    <main
      className="min-h-full flex sm:flex-center bg-background"
      style={themeVariables(theme)}
    >
      <div className="container flex flex-col sm:space-y-9 sm:my-9">
        {logoUrl && (
          <div className="z-0 fixed left-0 right-0 mt-[19px] sm:mt-0 sm:relative h-6 sm:h-8 flex-center">
            <img src={logoUrl} className="h-full" alt="Logo" />
          </div>
        )}
        <div
          className={classNames(
            "z-10 justify-center flex-grow sm:flex-grow-0 flex flex-col",
            {
              "pt-16 sm:pt-0": logoUrl,
            },
          )}
        >
          {children}
        </div>
      </div>
    </main>
  );
};

export interface ThemeVariables extends CSSProperties {
  "--color-primary": string;
  "--color-text": string;
  "--color-text-light": string;
  "--color-text-lighter": string;
  "--color-divider": string;
  "--color-background": string;
  "--color-card": string;
}

const themeVariable = (color: HexColor) => hexColorToRgb(color).join(" ");

const themeVariables = (theme?: Theme | null): ThemeVariables | undefined => {
  if (!theme) return;
  return {
    "--color-primary": themeVariable(theme.primaryColor),
    "--color-text": themeVariable(theme.textColor),
    "--color-text-light": themeVariable(theme.textLightColor),
    "--color-text-lighter": themeVariable(theme.textLighterColor),
    "--color-divider": themeVariable(theme.dividerColor),
    "--color-background": themeVariable(theme.backgroundColor),
    "--color-card": themeVariable(theme.cardColor),
  };
};
