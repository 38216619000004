import { CheckCircleIcon } from "@heroicons/react/outline";
import React from "react";
import { useOutletContext } from "react-router-dom";

import { AppContext } from "../App";
import { SecondaryLink } from "../components/Button";
import Layout from "../components/Layout";

export const FinalizedPage = () => {
  const { apiData } = useOutletContext<AppContext>();
  const attachments = apiData.invitationState.attachments;

  return (
    <Layout>
      <div className="flex flex-col max-w-lg w-full self-center flex-grow">
        <div className="flex-center space-x-3 sm:space-x-4 text-primary text-lg sm:text-xl font-medium">
          <CheckCircleIcon className="h-10 sm:h-12" />
          <div>Your appointment is finished</div>
        </div>
        {attachments && attachments.length > 0 && (
          <>
            <h3 className="font-semibold mt-12 mb-5">Files shared with you:</h3>
            <div className="flex flex-col space-y-2">
              {attachments.map((attachment) => (
                <SecondaryLink
                  key={attachment.urlV2.url}
                  href={attachment.urlV2.url}
                  target="_blank"
                >
                  {attachment.fileName}
                </SecondaryLink>
              ))}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};
