import React from "react";

import Layout from "../components/Layout";

export const CancelledPage = () => {
  return (
    <Layout title="Appointment cancelled">
      <div className="flex flex-col max-w-lg w-full self-center flex-grow">
        <p className="text-text-light text-center my-3">
          Your appointment was cancelled. Please use another booking link.
        </p>
      </div>
    </Layout>
  );
};
