import { ChevronLeftIcon } from "@heroicons/react/outline";
import React from "react";
import { useNavigate } from "react-router-dom";

const Layout = ({
  title,
  showBack,
  children,
}: {
  title?: string;
  showBack?: boolean;
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  return (
    <div className="bg-card overflow-hidden shadow sm:rounded-lg divide-y divide-divider flex-grow flex flex-col">
      {title && (
        <div className="px-4 py-5 sm:px-6 flex items-center">
          {showBack && (
            <button
              className="absolute flex-center py-2 space-x-2 text-text-light"
              onClick={() => navigate(-1)}
            >
              <ChevronLeftIcon height={20} />
              <span className="hidden sm:block">Back</span>
            </button>
          )}

          <h3 className="text-lg leading-6 font-medium text-text text-center flex-grow">
            {title}
          </h3>
        </div>
      )}
      <div className="px-4 py-5 sm:p-6 flex flex-col flex-grow justify-center">
        {children}
      </div>
    </div>
  );
};

export default Layout;
